import React, { useState } from "react";

import * as styles from "./encode-url.module.scss";

export const EncodeUrl = () => {
  const [text, setText] = useState("http://www.example.com/?q=hi there");
  const [encodingType, setEncodingType] = useState("encodeURI");

  const encode = (text, type) => {
    if (type === "encodeURI") return encodeURI(text);
    if (type === "encodeURIComponent") return encodeURIComponent(text);
  };

  return (
    <div className={styles.encodeUrl}>
      <b>Playground &raquo;</b> Paste in some url or just a string of characters to see which
      characters are replaced. You can choose the encoding function.
      <div className={styles.radio}>
        <input
          type="radio"
          id="encodeURI"
          name="encode"
          value="encodeURI"
          checked={encodingType === "encodeURI"}
          onChange={(e) => setEncodingType(e.target.value)}
        />
        <label htmlFor="encodeURI">encodeURI()</label>
      </div>
      <div className={styles.radio}>
        <input
          type="radio"
          id="encodeURIComponent"
          name="encode"
          value="encodeURIComponent"
          checked={encodingType === "encodeURIComponent"}
          onChange={(e) => setEncodingType(e.target.value)}
        />
        <label htmlFor="encodeURIComponent">encodeURIComponent()</label>
      </div>
      <input
        type="text"
        value={text}
        className={styles.textInput}
        maxLength={300}
        onChange={(e) => setText(e.target.value)}
      />
      <p className={styles.result}>
        {encode(text, encodingType) || "Here the encoded string will be displayed."}
      </p>
    </div>
  );
};
