import React from "react";

import * as styles from "./note.module.scss";

export const Note = ({ children }) => {
  return (
    <div className={styles.note}>
      <b>Reminder &raquo;</b> {children}
    </div>
  );
};
